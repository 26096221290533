import { Box, Typography } from '@mui/material';
import { ReactChild } from 'react';

interface ElementProps {
  title: string;
  content: ReactChild;
  icon: string;
}

export const Element = ({ title, content, icon }: ElementProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Box>
        <img src={icon} alt={title + ' ' + content} />
      </Box>
      <Box ml="20px" display="flex" flexDirection="column" alignItems="center">
        <Typography
          component="h4"
          fontWeight={900}
          fontSize={35}
          color="#004643"
          lineHeight="47px"
        >
          {title}
        </Typography>
        <Typography color="rgba(0, 0, 0, 0.6)" fontSize={20}>
          {content}
        </Typography>
      </Box>
    </Box>
  );
};
